import axios from '@axios'

export default {
  getListAccount(params) {
    return axios.get('/api/ads-accounts', { params })
  },

  getListTicket(params) {
    return axios.get('/api/tickets', { params })
  },

  createAdsAccount(params) {
    return axios.post('/api/tickets', params)
  },

  connectTiktok() {
    return axios.get('/api/users/request-connect-tiktok')
  },

  createBusinessAccount(params) {
    return axios.post('/api/bc/create', params)
  },

  getBusinessAccount() {
    return axios.get('/api/bc')
  },

  getCountryRegion() {
    return axios.get('/api/configuration/self-serve-ad-countries')
  },

  getPhoneRegion() {
    return axios.get('/api/configuration/phone-region-codes')
  },

  getSecondaryIndustries() {
    return axios.get('/api/configuration/secondary-industries')
  },

  listAdsAccount(params) {
    return axios.get('/api/tickets', params)
  },

  createAdsAccountNew(params) {
    return axios.post('/api/ads-accounts/request', params)
  },
}
