import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // get account list
  GET_ACCOUNTS_REQUEST(state) {
    REQUEST(state)
  },
  GET_ACCOUNTS_SUCCESS(state, data) {
    SUCCESS(state)
    state.listAccount = data
  },
  GET_ACCOUNTS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get ticket list
  GET_TICKET_REQUEST(state) {
    REQUEST(state)
  },
  GET_TICKET_SUCCESS(state, data) {
    SUCCESS(state)
    state.listTicket = data
  },
  GET_TICKET_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // create account
  CREATE_ADS_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  CREATE_ADS_ACCOUNT_SUCCESS(state) {
    SUCCESS(state)
  },
  CREATE_ADS_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // connect tiktok account
  CONNECT_TIKTOK_REQUEST(state) {
    REQUEST(state)
  },
  CONNECT_TIKTOK_SUCCESS(state, data) {
    SUCCESS(state)
    state.tiktokInfo = data
  },
  CONNECT_TIKTOK_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // connect tiktok account
  CREATE_BUSINESS_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  CREATE_BUSINESS_ACCOUNT_SUCCESS(state) {
    SUCCESS(state)
    // state.listBusinessAccounts = data
  },
  CREATE_BUSINESS_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get business accounts
  GET_BUSINESS_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  GET_BUSINESS_ACCOUNT_SUCCESS(state, data) {
    SUCCESS(state)
    state.listBusinessAccounts = data
  },
  GET_BUSINESS_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error)
  },
  SET_BUSINESS_ACCOUNT_MESSAGE(state, error) {
    state.listBusinessAccounts.message = error
    if (error) {
      state.tiktokAccountCurrent = {
        displayName: '',
        id: '',
      }
    }
  },

  // get BC Country Region
  GET_COUNTRY_REGION_REQUEST(state) {
    REQUEST(state)
  },
  GET_COUNTRY_REGION_SUCCESS(state, data) {
    SUCCESS(state)
    state.listCountryRegion = data
  },
  GET_COUNTRY_REGION_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get BC phone region
  GET_PHONE_REGION_REQUEST(state) {
    REQUEST(state)
  },
  GET_PHONE_REGION_SUCCESS(state, data) {
    SUCCESS(state)
    state.listPhoneRegion = data
  },
  GET_PHONE_REGION_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get BC Secondary Industry
  GET_SECONDARY_INDUSTRIES_REQUEST(state) {
    REQUEST(state)
  },
  GET_SECONDARY_INDUSTRIES_SUCCESS(state, data) {
    SUCCESS(state)
    state.listSecondaryIndustry = data
  },
  GET_SECONDARY_INDUSTRIES_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get ad accounts
  GET_ADS_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  GET_ADS_ACCOUNT_SUCCESS(state, data) {
    SUCCESS(state)
    state.listAdsAccount = data
  },
  GET_ADS_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error)
  },
  SET_TIKTOK_ACCOUNT_CURRENT(state, data) {
    state.tiktokAccountCurrent = data?.tiktok || {
      displayName: '',
      id: '',
    }
  },
}
