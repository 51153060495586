import { DEFAULT_STATE } from '@/constants'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
    listAccount: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },

    listTicket: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    listBusinessAccounts: {
      list: [],
      page_info: {
        total_page: 0,
        page: 1,
      },
      message: '',
    },
    listAdsAccount: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    listCountryRegion: [
      {
        countryCode: '',
        country: '',
      },
    ],
    listPhoneRegion: [
      {
        phone_region_calling_code: '',
        phone_region_code: '',
        phone_region_name: '',
      },
    ],
    listSecondaryIndustry: [
      {
        industryName: '',
        industryID: '',
      },
    ],
    tiktokInfo: null,
    tiktokAccountCurrent: {
      displayName: '',
      id: '',
    },
  },
  getters,
  mutations,
  actions,
}
