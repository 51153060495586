import axios from '@axios'

export default {
  login(params) {
    return axios.post('/api/users/login', params)
  },
  register(params) {
    const { tokenCaptcha, ...realParams } = params
    return axios.post(
      `/api/users/register?g-recaptcha-response=${params.tokenCaptcha}`,
      realParams,
    )
  },
  resetInit(params) {
    const { tokenCaptcha, ...realParams } = params
    return axios.post(
      `/api/users/forgot-password?g-recaptcha-response=${params.tokenCaptcha}`,
      realParams,
    )
  },
  resetFinish(params) {
    return axios.post('/api/users/reset-password', params)
  },
  getAccountInfo() {
    return axios.get('/api/users')
  },
  updateAccount(params) {
    return axios.patch('/api/users', params)
  },
  getAccountReport() {
    return axios.get('/api/users/report')
  },
  getGeneralConfig() {
    return axios.get('/api/configuration/value')
  },
  updatePassword(params) {
    return axios.patch(
      `${
        process.env.VUE_APP_PLATFORM_NAME === 'Ecomdy'
          ? `${process.env.VUE_APP_LOGIN_URL}/api/auth/change-password`
          : `${process.env.VUE_APP_SERVER_API}/api/users/change-password`
      }`,
      params,
    )
  },
  verifyPhoneNumber(params) {
    return axios.post('/api/users/send-phone-otp', params)
  },
  verifyShopifyConnect(params) {
    return axios.get('/api/shopify/connect/request', { params })
  },
  loginFacebook() {
    const baseURL = process.env.VUE_APP_NODE_ENV === 'production'
      ? window.location.origin
      : process.env.VUE_APP_SERVER_API
    window.location.href = `${baseURL}/api/users/facebook/auth`
  },
  loginGoogle() {
    const baseURL = process.env.VUE_APP_NODE_ENV === 'production'
      ? window.location.origin
      : process.env.VUE_APP_SERVER_API
    window.location.href = `${baseURL}/api/users/google/auth`
  },
  requestVerifyEmail() {
    return axios.post('/api/users/request-verify-email')
  },
  requestTikTokAppCenter(params) {
    return axios.post(
      `${process.env.VUE_APP_LOGIN_URL}/api/auth/login/tiktok-app-center`,
      params,
    )
  },

  sendCodeVerifyEmail(params) {
    const { tokenRecaptcha, ...realParams } = params
    return axios.post(
      `${process.env.VUE_APP_LOGIN_URL}/api/auth/users/send-code-verify-email-ttac?g-recaptcha-response=${tokenRecaptcha}`,
      realParams,
    )
  },

  verifyEmailByCode(params) {
    const { tokenRecaptcha, ...realParams } = params
    return axios.post(
      `${process.env.VUE_APP_LOGIN_URL}/api/auth/users/tiktok-app-center?g-recaptcha-response=${tokenRecaptcha}`,
      realParams,
    )
  },

  getNotifications(params) {
    return axios.get('/api/notifications', { params })
  },

  readNotifications(params) {
    return axios.post('/api/notifications/read-all', {}, { params })
  },
}
