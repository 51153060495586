import origMoment from 'moment'
import { extendMoment } from 'moment-range'

const moment = extendMoment(origMoment)

// eslint-disable-next-line import/prefer-default-export
export const IMAGE_URL = '/api/upload/image-source/'

export const DEFAULT_SIZE = 10

export const DEFAULT_SHOW_PAGE_ITEM = 10

export const TOOLBAR_EDITOR_OPTIONS = [
  [{ font: [] }, { size: [] }],
  ['bold', 'italic', 'underline'],
]

export const CURRENCY = ['USD', 'VND']

const today = moment()

export const DATE_RANGE = [
  {
    name: 'Today',
    value: moment.range(today.clone().subtract(0, 'days'), today.clone()),
  },
  {
    name: 'Yesterday',
    value: moment.range(today.clone().subtract(1, 'days'), today.clone().subtract(1, 'days')),
  },
  {
    name: 'Last 7 days',
    value: moment.range(today.clone().subtract(6, 'days'), today.clone()),
  },
  {
    name: 'Last 30 days',
    value: moment.range(today.clone().subtract(29, 'days'), today.clone()),
  },
  {
    name: 'This Week',
    value: moment.range(today.clone().subtract(0, 'weeks').startOf('weeks'), today.clone().subtract(0, 'weeks').endOf('weeks')),
  },
  {
    name: 'Last Week',
    value: moment.range(today.clone().subtract(1, 'weeks').startOf('weeks'), today.clone().subtract(1, 'weeks').endOf('weeks')),
  },

  {
    name: 'This Month',
    value: moment.range(today.clone().subtract(0, 'months').startOf('month'), today.clone().subtract(0, 'months').endOf('month')),
  },
  {
    name: 'Last Month',
    value: moment.range(today.clone().subtract(1, 'months').startOf('month'), today.clone().subtract(1, 'months').endOf('month')),
  },
]

export const TOOLTIP = {
  cost: 'The estimated total amount of money you\'ve spent on your campaign, ad group or ad during its schedule.',
  cpc: 'The average amount of money you\'ve spent on a click.',
  cpa: 'The average amount of money you\'ve spent on a conversion.',
  impression: 'The number of times your ads were on screen.',
  ctr: 'The percentage of times people saw your ad and performed a click.',
  clicks: 'The number of clicks on your ads.',
  cpm: 'The average amount of money you\'ve spent per thousand impressions.',
  cvr: 'The percentage of results you received out of all the clicks of your ads.',
}

export const SOCIAL_OPTIONS = [
  {
    name: 'Contact via Telegram',
    value: 'telegram',
  },
  {
    name: 'Contact via Facebook',
    value: 'facebook',
  },
  {
    name: 'Contact via WhatsApp',
    value: 'whatsApp',
  },
]

export const TICKET_TYPE = {
  RECHARGE_USER_BALANCE: 'recharge_user_balance',
  CREATE_ADS_ACCOUNT: 'create_ads_account',
  REFUND: 'request_refund',
  SETUP_COST: 'setup_cost',
}

export const TERMS_LINK = 'https://www.notion.so/ecomdy/ECOMDY-MEDIA-TERM-OF-SERVICE-efc10a7f6bc64e7db8f5f8522037fdb2'

export const COUNTRIES = [
  {
    name: 'Afghanistan',
    code: 'AF',
  },
  {
    name: 'Åland Islands',
    code: 'AX',
  },
  {
    name: 'Albania',
    code: 'AL',
  },
  {
    name: 'Algeria',
    code: 'DZ',
  },
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'Andorra',
    code: 'AD',
  },
  {
    name: 'Angola',
    code: 'AO',
  },
  {
    name: 'Anguilla',
    code: 'AI',
  },
  {
    name: 'Antarctica',
    code: 'AQ',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
  },
  {
    name: 'Argentina',
    code: 'AR',
  },
  {
    name: 'Armenia',
    code: 'AM',
  },
  {
    name: 'Aruba',
    code: 'AW',
  },
  {
    name: 'Australia',
    code: 'AU',
  },
  {
    name: 'Austria',
    code: 'AT',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
  },
  {
    name: 'Bahamas',
    code: 'BS',
  },
  {
    name: 'Bahrain',
    code: 'BH',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
  },
  {
    name: 'Barbados',
    code: 'BB',
  },
  {
    name: 'Belarus',
    code: 'BY',
  },
  {
    name: 'Belgium',
    code: 'BE',
  },
  {
    name: 'Belize',
    code: 'BZ',
  },
  {
    name: 'Benin',
    code: 'BJ',
  },
  {
    name: 'Bermuda',
    code: 'BM',
  },
  {
    name: 'Bhutan',
    code: 'BT',
  },
  {
    name: 'Bolivia',
    code: 'BO',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    code: 'BQ',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
  },
  {
    name: 'Botswana',
    code: 'BW',
  },
  {
    name: 'Bouvet Island',
    code: 'BV',
  },
  {
    name: 'Brazil',
    code: 'BR',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
  },
  {
    name: 'Brunei Darussalam',
    code: 'BN',
  },
  {
    name: 'Bulgaria',
    code: 'BG',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
  },
  {
    name: 'Burundi',
    code: 'BI',
  },
  {
    name: 'Cambodia',
    code: 'KH',
  },
  {
    name: 'Cameroon',
    code: 'CM',
  },
  {
    name: 'Canada',
    code: 'CA',
  },
  {
    name: 'Cape Verde',
    code: 'CV',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
  },
  {
    name: 'Chad',
    code: 'TD',
  },
  {
    name: 'Chile',
    code: 'CL',
  },
  {
    name: 'China',
    code: 'CN',
  },
  {
    name: 'Christmas Island',
    code: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
  },
  {
    name: 'Colombia',
    code: 'CO',
  },
  {
    name: 'Comoros',
    code: 'KM',
  },
  {
    name: 'Congo, Republic of the (Brazzaville)',
    code: 'CG',
  },
  {
    name: 'Congo, the Democratic Republic of the (Kinshasa)',
    code: 'CD',
  },
  {
    name: 'Cook Islands',
    code: 'CK',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
  },
  {
    name: "Côte d'Ivoire, Republic of",
    code: 'CI',
  },
  {
    name: 'Croatia',
    code: 'HR',
  },
  {
    name: 'Cuba',
    code: 'CU',
  },
  {
    name: 'Curaçao',
    code: 'CW',
  },
  {
    name: 'Cyprus',
    code: 'CY',
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
  },
  {
    name: 'Denmark',
    code: 'DK',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
  },
  {
    name: 'Dominica',
    code: 'DM',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
  },
  {
    name: 'Ecuador',
    code: 'EC',
  },
  {
    name: 'Egypt',
    code: 'EG',
  },
  {
    name: 'El Salvador',
    code: 'SV',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
  },
  {
    name: 'Eritrea',
    code: 'ER',
  },
  {
    name: 'Estonia',
    code: 'EE',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    code: 'FK',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
  },
  {
    name: 'Fiji',
    code: 'FJ',
  },
  {
    name: 'Finland',
    code: 'FI',
  },
  {
    name: 'France',
    code: 'FR',
  },
  {
    name: 'French Guiana',
    code: 'GF',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
  },
  {
    name: 'French Southern and Antarctic Lands',
    code: 'TF',
  },
  {
    name: 'Gabon',
    code: 'GA',
  },
  {
    name: 'Gambia, The',
    code: 'GM',
  },
  {
    name: 'Georgia',
    code: 'GE',
  },
  {
    name: 'Germany',
    code: 'DE',
  },
  {
    name: 'Ghana',
    code: 'GH',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
  },
  {
    name: 'Greece',
    code: 'GR',
  },
  {
    name: 'Greenland',
    code: 'GL',
  },
  {
    name: 'Grenada',
    code: 'GD',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Guatemala',
    code: 'GT',
  },
  {
    name: 'Guernsey',
    code: 'GG',
  },
  {
    name: 'Guinea',
    code: 'GN',
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
  },
  {
    name: 'Guyana',
    code: 'GY',
  },
  {
    name: 'Haiti',
    code: 'HT',
  },
  {
    name: 'Heard Island and McDonald Islands',
    code: 'HM',
  },
  {
    name: 'Holy See (Vatican City)',
    code: 'VA',
  },
  {
    name: 'Honduras',
    code: 'HN',
  },
  {
    name: 'Hong Kong',
    code: 'HK',
  },
  {
    name: 'Hungary',
    code: 'HU',
  },
  {
    name: 'Iceland',
    code: 'IS',
  },
  {
    name: 'India',
    code: 'IN',
  },
  {
    name: 'Indonesia',
    code: 'ID',
  },
  {
    name: 'Iran, Islamic Republic of',
    code: 'IR',
  },
  {
    name: 'Iraq',
    code: 'IQ',
  },
  {
    name: 'Ireland',
    code: 'IE',
  },
  {
    name: 'Isle of Man',
    code: 'IM',
  },
  {
    name: 'Israel',
    code: 'IL',
  },
  {
    name: 'Italy',
    code: 'IT',
  },
  {
    name: 'Jamaica',
    code: 'JM',
  },
  {
    name: 'Japan',
    code: 'JP',
  },
  {
    name: 'Jersey',
    code: 'JE',
  },
  {
    name: 'Jordan',
    code: 'JO',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
  },
  {
    name: 'Kenya',
    code: 'KE',
  },
  {
    name: 'Kiribati',
    code: 'KI',
  },
  {
    name: "Korea, Democratic People's Republic of",
    code: 'KP',
  },
  {
    name: 'Korea, Republic of',
    code: 'KR',
  },
  {
    name: 'Kuwait',
    code: 'KW',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
  },
  {
    name: 'Laos',
    code: 'LA',
  },
  {
    name: 'Latvia',
    code: 'LV',
  },
  {
    name: 'Lebanon',
    code: 'LB',
  },
  {
    name: 'Lesotho',
    code: 'LS',
  },
  {
    name: 'Liberia',
    code: 'LR',
  },
  {
    name: 'Libya',
    code: 'LY',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
  },
  {
    name: 'Lithuania',
    code: 'LT',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
  },
  {
    name: 'Macao',
    code: 'MO',
  },
  {
    name: 'Macedonia, Republic of',
    code: 'MK',
  },
  {
    name: 'Madagascar',
    code: 'MG',
  },
  {
    name: 'Malawi',
    code: 'MW',
  },
  {
    name: 'Malaysia',
    code: 'MY',
  },
  {
    name: 'Maldives',
    code: 'MV',
  },
  {
    name: 'Mali',
    code: 'ML',
  },
  {
    name: 'Malta',
    code: 'MT',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
  },
  {
    name: 'Martinique',
    code: 'MQ',
  },
  {
    name: 'Mauritania',
    code: 'MR',
  },
  {
    name: 'Mauritius',
    code: 'MU',
  },
  {
    name: 'Mayotte',
    code: 'YT',
  },
  {
    name: 'Mexico',
    code: 'MX',
  },
  {
    name: 'Micronesia, Federated States of',
    code: 'FM',
  },
  {
    name: 'Moldova',
    code: 'MD',
  },
  {
    name: 'Monaco',
    code: 'MC',
  },
  {
    name: 'Mongolia',
    code: 'MN',
  },
  {
    name: 'Montenegro',
    code: 'ME',
  },
  {
    name: 'Montserrat',
    code: 'MS',
  },
  {
    name: 'Morocco',
    code: 'MA',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
  },
  {
    name: 'Myanmar',
    code: 'MM',
  },
  {
    name: 'Namibia',
    code: 'NA',
  },
  {
    name: 'Nauru',
    code: 'NR',
  },
  {
    name: 'Nepal',
    code: 'NP',
  },
  {
    name: 'Netherlands',
    code: 'NL',
  },
  {
    name: 'New Caledonia',
    code: 'NC',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
  },
  {
    name: 'Niger',
    code: 'NE',
  },
  {
    name: 'Nigeria',
    code: 'NG',
  },
  {
    name: 'Niue',
    code: 'NU',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Norway',
    code: 'NO',
  },
  {
    name: 'Oman',
    code: 'OM',
  },
  {
    name: 'Pakistan',
    code: 'PK',
  },
  {
    name: 'Palau',
    code: 'PW',
  },
  {
    name: 'Palestine, State of',
    code: 'PS',
  },
  {
    name: 'Panama',
    code: 'PA',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
  },
  {
    name: 'Paraguay',
    code: 'PY',
  },
  {
    name: 'Peru',
    code: 'PE',
  },
  {
    name: 'Philippines',
    code: 'PH',
  },
  {
    name: 'Pitcairn',
    code: 'PN',
  },
  {
    name: 'Poland',
    code: 'PL',
  },
  {
    name: 'Portugal',
    code: 'PT',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Qatar',
    code: 'QA',
  },
  {
    name: 'Réunion',
    code: 'RE',
  },
  {
    name: 'Romania',
    code: 'RO',
  },
  {
    name: 'Russian Federation',
    code: 'RU',
  },
  {
    name: 'Rwanda',
    code: 'RW',
  },
  {
    name: 'Saint Barthélemy',
    code: 'BL',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
  },
  {
    name: 'Saint Martin',
    code: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
  },
  {
    name: 'Samoa',
    code: 'WS',
  },
  {
    name: 'San Marino',
    code: 'SM',
  },
  {
    name: 'Sao Tome and Principe',
    code: 'ST',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
  },
  {
    name: 'Senegal',
    code: 'SN',
  },
  {
    name: 'Serbia',
    code: 'RS',
  },
  {
    name: 'Seychelles',
    code: 'SC',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
  },
  {
    name: 'Singapore',
    code: 'SG',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    code: 'SX',
  },
  {
    name: 'Slovakia',
    code: 'SK',
  },
  {
    name: 'Slovenia',
    code: 'SI',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
  },
  {
    name: 'Somalia',
    code: 'SO',
  },
  {
    name: 'South Africa',
    code: 'ZA',
  },
  {
    name: 'South Georgia and South Sandwich Islands',
    code: 'GS',
  },
  {
    name: 'South Sudan',
    code: 'SS',
  },
  {
    name: 'Spain',
    code: 'ES',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
  },
  {
    name: 'Sudan',
    code: 'SD',
  },
  {
    name: 'Suriname',
    code: 'SR',
  },
  {
    name: 'Swaziland',
    code: 'SZ',
  },
  {
    name: 'Sweden',
    code: 'SE',
  },
  {
    name: 'Switzerland',
    code: 'CH',
  },
  {
    name: 'Syrian Arab Republic',
    code: 'SY',
  },
  {
    name: 'Taiwan',
    code: 'TW',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of',
    code: 'TZ',
  },
  {
    name: 'Thailand',
    code: 'TH',
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
  },
  {
    name: 'Togo',
    code: 'TG',
  },
  {
    name: 'Tokelau',
    code: 'TK',
  },
  {
    name: 'Tonga',
    code: 'TO',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
  },
  {
    name: 'Tunisia',
    code: 'TN',
  },
  {
    name: 'Turkey',
    code: 'TR',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
  },
  {
    name: 'Uganda',
    code: 'UG',
  },
  {
    name: 'Ukraine',
    code: 'UA',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
  },
  {
    name: 'United Kingdom',
    code: 'GB',
  },
  {
    name: 'United States',
    code: 'US',
  },
  {
    name: 'United States Minor Outlying Islands',
    code: 'UM',
  },
  {
    name: 'Uruguay',
    code: 'UY',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
  },
  {
    name: 'Venezuela, Bolivarian Republic of',
    code: 'VE',
  },
  {
    name: 'Vietnam',
    code: 'VN',
  },
  {
    name: 'Virgin Islands, British',
    code: 'VG',
  },
  {
    name: 'Virgin Islands, U.S.',
    code: 'VI',
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
  },
  {
    name: 'Western Sahara',
    code: 'EH',
  },
  {
    name: 'Yemen',
    code: 'YE',
  },
  {
    name: 'Zambia',
    code: 'ZM',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
  },
]

export const BLOCKED_COUNTRIES = [
  'MA',
  'JO',
  'PK',
  'IQ',
]

export const LIST_SHORTEN_URL = [
  'bit.ly',
  'tinyurl.com',
  'shorturl.at',
  'cutt.ly',
  'gg.gg',
  'urlis.net',
  'wa.me',
  'linktr.ee',
  'api.whatsapp.com',
]

export const LIST_INVALID_BUSINESS_WEBSITE = [
  'google.com',
  'youtube.com',
  'facebook.com',
  'web.whatsapp.com',
]

export const LIST_URL_REDIRECT = [
  '/login',
  '/register',
  '/forgot-password',
  '/reset-password',
  '/verify-email',
  '/verify-email-fail',
]

const borderRadiusBase = '4px'

export const STYLES = {
  TIKTOK_APP_CENTER: {
    '--font-family-sans-serif': 'TikTokText-Regular, Helvetica, Arial, serif',
    '--font-family-medium': 'TikTokText-Medium, Helvetica, Arial, serif',
    '--step-guide-color': '#FE2C55',
    '--border-radius-sm': borderRadiusBase,
    '--border-radius-base': borderRadiusBase,
    '--border-radius-md': borderRadiusBase,
    '--border-radius-lg': borderRadiusBase,
    '--border-radius-xl': borderRadiusBase,
    '--border-radius-2xl': borderRadiusBase,
    '--input-border-radius-base': borderRadiusBase,
    '--btn-border-radius-base': borderRadiusBase,
    '--table-border-radius': borderRadiusBase,
    '--setup-guide-border-radius': borderRadiusBase,
    '--icon-document-color': 'rgba(38, 103, 255, 1)',
    '--text-live-chat-color': '#FE2C55',
    '--button-color-start': '#FE2C55',
    '--button-color-end': '#FE2C55',
    '--button-color-icon': '#FE2C55',
    '--btn-submit-box-shadow': 'rgba(254,44,85,0.6)',
    '--btn-action-ad-account-color': '#FE2C55',
    '--text-money': 'black',
  },
  EMEDIA: {
    '--button-color-start': '#0B68F9',
    '--button-color-end': '#0B68F9',
    '--button-color-icon': '#0B68F9',
    '--btn-submit-box-shadow': '#0b68f99b',
    '--tab-payment-width': '134px',
  },

  GYXEN: {
    '--button-color-start': '#05a0e3',
    '--button-color-end': '#05a0e3',
    '--button-color-icon': '#05a0e3',
    '--btn-submit-box-shadow': '#05a0e39b',
    '--tab-payment-width': '134px',
  },

  GOODVIBES: {
    '--button-color-start': '#241a30',
    '--button-color-end': '#241a30',
    '--button-color-icon': '#241a30',
    '--btn-submit-box-shadow': '#241a309b',
    '--tab-payment-width': '134px',
  },

  AD_SOLUTIONS: {
    // '--button-color-start': '#211680',
    // '--button-color-end': '#30c4cb',
    // '--button-color-icon': '#211680',
    // '--btn-submit-box-shadow': '#2414849b',
    '--tab-payment-width': '134px',
    '--text-live-chat-color': '#241484',
    '--link-register-now-color': '#241484',
    '--link-register-now-hover-color': '#1c0f67',
  },
}
