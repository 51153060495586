import { DEFAULT_GETTERS } from '@/constants'

export default {
  ...DEFAULT_GETTERS,
  listAccount: state => state.listAccount,
  listTicket: state => state.listTicket,
  listBusinessAccounts: state => state.listBusinessAccounts,
  listAdsAccount: state => state.listAdsAccount,
  listCountryRegion: state => state.listCountryRegion,
  listPhoneRegion: state => state.listPhoneRegion,
  listSecondaryIndustry: state => state.listSecondaryIndustry,
  tiktokInfo: state => state.tiktokInfo,
  tiktokAccountCurrent: state => state.tiktokAccountCurrent,
}
